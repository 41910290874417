<template>
  <div class="about">
        <video class="video maodian" src="https://www.szinm2020.com/img/HomePage/0826-INM-%E5%AA%92%E4%BD%93%E4%BC%98%E5%8A%BF%EF%BC%88%E5%9B%BD%E8%AF%AD%EF%BC%89-30s%20(2).mp4" autoplay loop muted width="100%"></video>
        <van-sidebar style="position: fixed;top: 0;"  v-model="activeKey" v-show="show">
          <van-sidebar-item title="首页" />
          <van-sidebar-item title="关于创科" />
          <van-sidebar-item title="业务范围" />
          <van-sidebar-item title="红人馆" />
          <van-sidebar-item title="合作案例" />
          <van-sidebar-item title="商务合作" />
        </van-sidebar>
        <div class="hander">
            <p class="p1">创科新媒体</p>
            <p class="p2">INNOTECH NEW MEDIA</p>
          <p class="p3">以网红直播业务为基础，发展网红经济，内容电商，网络红人短视频运营等，实现粉丝经济效应，
             为各领域品牌客户提供：互联网直播，新媒体视频系列，品牌营销推广，内容电商，直播电商等业务，
             并结合品牌本身的调性，制定出合适的营销方案，为品牌实现流量转化，助力品牌实现品效合一。</p>
        </div>
        <div class="youshi">
            <!-- <p class="p1">我们的优势</p> -->
            <van-swipe :autoplay="3000" >
              <van-swipe-item v-for="image in oImg" :key="image.id" >
                <img :src="image.src" width="100%">
                <div class="p" style="width: 100vw;line-height: 1;position: absolute; bottom: 20%;color: #fff;">
                      <p class="p2">{{image.string1}}</p>
                      <p class="p3">{{image.string2}}</p>
                </div>
              </van-swipe-item>
            </van-swipe>
            <!-- <div class="tupian">
                <ul>
                  <li v-for="item in oImg" :key="item.id">
                    <van-image width="100vw" :src="item.src"/>
                    <div class="p">
                      <p class="p2">{{item.string1}}</p>
                      <p class="p3">{{item.string2}}</p>
                    </div>
                  </li>
                </ul>
              </div> -->
        </div>
        <div class="yewufanwei1">
          <p class="p1">业务范围</p>
          <div class="yewufanwei2">
            <van-cell-group>
              <!-- <van-cell title="直播业务" label="通过视频直播的形式与全国多家直播平台合作，实现内容输出"/>
              <van-cell title="网红营销" label="布局整合主流的内容电商业务，在全网多平台运营了多个优质的直播带货网红，构建以粉丝为中心的精准营销生态​​，为品牌实现流量转化，使产品达到销售转化"/>
              <van-cell title="传播推广" label="网红资源整合新媒体传媒，多种形式的广告推广合作模式，精准的投放通道，让您的推广更具穿透力"/>
              <van-cell title="内容制作" label="网红资源整合新媒体传媒，多种形式的广告推广合作模式，精准的投放通道，让您的推广更具穿透力"/>
              <van-cell title="直播业务" label="为客户提供优质的互联网资源(新媒体资源、网红资源）、优秀的线上媒体资源，为客户提供更精准、更高效的新时代品牌营销推广服务"/> -->
            </van-cell-group>
            <!-- <van-collapse v-model="activeName" accordion>
              <van-collapse-item title="直播业务" name="1">内容</van-collapse-item>
              <van-collapse-item title="网红营销" name="2">内容</van-collapse-item>
              <van-collapse-item title="传播推广" name="3">内容</van-collapse-item>
            </van-collapse> -->
            <!-- <img class="img1" src="https://www.szinm2020.com/img/Business/blbtu.png" alt=""> -->
            <!-- <ul>
                <li>
                  <img src="https://www.szinm2020.com/img/Business/dulifk01.png" alt="">
                  <p>直播业务</p>
                  <p>Live business</p>
                  <p>通过视频直播的形式与全国多家直播平台合作，实现内容输出</p>
                </li>
                <li>
                  <img src="https://www.szinm2020.com/img/Business/dulifk01.png" alt="">
                  <p>网红营销</p>
                  <p>Influencer marketing</p>
                  <p>布局整合主流的内容电商业务，在全网多平台运营了多个优质的直播带货网红，构建以粉丝为中心的精准营销生态​​，为品牌实现流量转化，使产品达到销售转化</p>
                </li>
                <li>
                  <img src="https://www.szinm2020.com/img/Business/dulifk01.png" alt="">
                  <p>传播推广</p>
                  <p>Communication and promotions</p>
                  <p>网红资源整合新媒体传媒，多种形式的广告推广合作模式，精准的投放通道，让您的推广更具穿透力</p>
                </li>
                <li>
                  <img src="https://www.szinm2020.com/img/Business/dulifk01.png" alt="">
                  <p>内容制作</p>
                  <p>Content production</p>
                  <p>网红资源整合新媒体传媒，多种形式的广告推广合作模式，精准的投放通道，让您的推广更具穿透力</p>
                </li>
                <li>
                  <img src="https://www.szinm2020.com/img/Business/dulifk01.png" alt="">
                  <p>整合营销</p>
                  <p>Integrated Marketing</p>
                  <p>为客户提供优质的互联网资源(新媒体资源、网红资源）、优秀的线上媒体资源，为客户提供更精准、更高效的新时代品牌营销推广服务</p>
                </li>
              </ul> -->
          </div>
        </div>
        <div class="hongrenzhaomu">
          <p class="p1">红人招募  就等你来</p>
          <p class="p2">THE RECRUITMENT OF CELEBRITIES IS WAITING <br>
FOR YOU</p>
          <van-swipe :autoplay="2000" >
              <van-swipe-item >
                <img style="width:100vw" src="https://www.szinm2020.com/img/RedPavilion/whzp01.png" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/RedPavilion/whzp02.png" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/RedPavilion/whzp03.png" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/RedPavilion/whzp04.png" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/RedPavilion/whzp05.png" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/RedPavilion/whzp06.png" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/RedPavilion/whzp07.png" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/RedPavilion/whzp08.png" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/RedPavilion/whzp09.png" alt="">
              </van-swipe-item>
            </van-swipe>
            <div class="baoming">
              <van-button class="anniu" type="info" @click="visible=true">立即报名</van-button>
            </div>
            <van-cell-group>
              <van-cell title="你将获得"  label="商业化收益分成、媒体推广、平台流量扶持，线下推广、H5宣传、百度百科、艺人百科等、才艺， 技能培训，网络，电视台等综艺节目、短视频剪辑制作、直播间、电商供应链，广告收益等" />
            </van-cell-group>
        </div>
        <div class="hzal2">
        <div class="yewufanwei1">
                <p class="p1">合作案例</p>
                <p class="p2">COOPERATION CASE</p>
              </div>
              <div class="hezuoanli2">
              <div class="case" v-for="item in currentarr" :key="item.id">
                    <a target="_blank" :href="item.url">
                        <img width="80%" :src="item.image" />
                        <p class="altime">{{item.date}}</p>
                        <p class="alneirong">{{item.title}}</p>
                    </a>
                </div>
                <div id="page" class="page_div">
                <el-pagination
                        background
                        @current-change="handleCurrentChange"
                        layout="prev, pager, next"
                        :page-size="9"
                        :total="27">
                </el-pagination>
                </div>
              </div>
      </div>
      <div class="lianxiwomen2" style="background:#f0f4f7">
        <baidu-map style="height:300px;" :center="center" :zoom="zoom" @ready="handler" :scroll-wheel-zoom="true">
              <bm-scale anchor="BMAP_ANCHOR_TOP_LEFT"></bm-scale>
              <!-- <bm-geolocation anchor="BMAP_ANCHOR_TOP_LEFT" :showAddressBar="true" :autoLocation="true"></bm-geolocation> -->
              <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT" ></bm-navigation>
              <bm-city-list anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-city-list>
              <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_SATELLITE_MAP']" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-map-type>
              <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>
              <bm-marker :position="center" :dragging="true" @click="infoWindowOpen">
              <bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen">
                <p>创科新媒体</p>
                <p>地址：深圳市 龙华区 星河WORLD二期C座 深圳创科新媒体</p>
                <p>电话：135-2875-1015</p>
                </bm-info-window>
              </bm-marker>
        </baidu-map>
        <img  class="erweima2" src="https://www.szinm2020.com/img/CombineCase/QRcode.png" alt="">
        <div class="lianxiwomenwenzi">
        <div class="saoma"><img style="display:inline" src="https://www.szinm2020.com/img/CombineCase/xxioc.png" /><span>&nbsp;扫码联系客服</span></div>
        <div><p><img style="margin-right:5px" src="https://www.szinm2020.com/img/CombineCase/zuojiioc.png" /><span> info@inm2020.com</span></p></div>
        <div><p><img style="margin-right:9px" src="https://www.szinm2020.com/img/CombineCase/sjioc.png" /><span> 135-2875-1015</span></p></div>
        <div><p><img style="margin-right:8px;" src="https://www.szinm2020.com/img/CombineCase/dizioc.png" /><span> 深圳市龙华区星河World二期C座20层</span></p></div>
        </div>
        </div>
        <div class="footer2">
          <!-- <img class="erweima" style="float:left" src="https://www.szinm2020.com/img/CombineCase/huiselogo.png" /> -->
                <span class="peianh">Copyright © 2020 szinm2020.com. <br><a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2020092676号</a></span>
        </div>
        <el-dialog
              :visible.sync="visible"
              :width="'98vw'"
              title="CONTACT US 联系我们"
              :modal = "false"
              >
        <el-form ref="form" :rules="rules" :model="form" label-width="80px"
        :validate-on-rule-change="false" @submit.native.prevent>
            <el-form-item label="您的姓名" prop="name">
                <el-input  v-model="form.name" ></el-input>
            </el-form-item>
            <el-form-item label="您的邮箱" prop="email">
                <el-input v-model="form.email" ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
                <el-input type="number" v-model="form.phone" ></el-input>
            </el-form-item>
            <el-form-item label="您的想法">
                <el-input v-model="form.idea"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit(form)">提交报名</el-button>
<!--                <el-button>取消</el-button>-->
            </el-form-item>
        </el-form>
    </el-dialog>
</div>
</template>
<script>
import axios from 'axios'
import Swiper from 'swiper/bundle' // 引入swiper.js
import 'swiper/swiper-bundle.min.css' // 引入swiper.css
/* import Froms from '../components/Froms.vue' */
export default {
  data () {
    const axios = require('axios')
    return {
      username: '',
      email: '',
      tell: '',
      xiangfa: '',
      show: false,
      show1: false,
      center: { lng: 0, lat: 0 },
      // center: {lng: 114.064052, lat: 22.610619},
      zoom: 3,
      visible: false,
      activeName: '0',
      activeKey: 0,
      form: {
        name: '',
        email: '',
        phone: '',
        idea: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入您的邮箱', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入您的联系方式', trigger: 'blur' }
        ]
      },
      oImg: [
        {
          id: 1,
          src: 'https://www.szinm2020.com/img/aboutCk/yous01.png',
          string1: '实力雄厚',
          string2: '独立2000平摄影基地'
        },
        {
          id: 2,
          src: 'https://www.szinm2020.com/img/aboutCk/yous02.png',
          string1: '专业团队',
          string2: '中港专业策划团队'
        },
        {
          id: 3,
          src: 'https://www.szinm2020.com/img/aboutCk/yous03.png',
          string1: '丰富资源',
          string2: '各主流平台深度合作'
        },
        {
          id: 4,
          src: 'https://www.szinm2020.com/img/aboutCk/yous04.png',
          string1: '专属方案',
          string2: '实现高效流量变现'
        }
      ],
      arr1: [{
        id: 1,
        date: '2020-08-20',
        title: '职场·后现代  EP.01 疫境前行，面部小颜...',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu01.png',
        url: 'https://www.iqiyi.com/v_128mj6bcxhc.html'
      }, {
        id: 2,
        date: '2020-08-20',
        title: '职场·后现代 EP.02 Jenny Chan...',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu02.png',
        url: 'https://www.iqiyi.com/v_1e6uo0kp6rk.html'
      }, {
        id: 3,
        date: '2020-08-14',
        title: '【职场后现代】 Andy   Change y...',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu03.png',
        url: 'https://www.iqiyi.com/v_1qehsex46gc.html'
      }, {
        id: 4,
        date: '2020-08-13',
        title: '盛装出席INM开业、张可頤望与民间高手合作！！',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu04.png',
        url: 'https://www.iqiyi.com/v_n0li6owwqc.html'
      }, {
        id: 5,
        date: '2020-08-13',
        title: '李惠敏首次与INM合作、分享新手妈妈养儿经',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu05.png',
        url: 'https://www.iqiyi.com/v_1n5tp1o8cgc.html'
      }, {
        id: 6,
        date: '2020-08-13',
        title: '蒋怡透露建立烹饪频道、分享星級入厨心得！！',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu06.png',
        url: 'https://www.iqiyi.com/v_be64aya7mo.html'
      }, {
        id: 7,
        date: '2020-08-13',
        title: '叶韦彤双线发展、预告将举办音乐比赛！！',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu07.png',
        url: 'https://www.iqiyi.com/v_1wmer8ffw7s.html'
      }, {
        id: 8,
        date: '2020-08-13',
        title: '容颜救兵，EP.04黑，白咖啡好坏你要知道',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu08.png',
        url: 'https://www.iqiyi.com/v_1tvy8ydmyek.html'
      }, {
        id: 9,
        date: '2020-08-13',
        title: 'EP.03 急救面膜點揀好？DickSir教你...',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu09.png',
        url: 'https://www.iqiyi.com/v_1kcxzxhofos.html'
      }],
      arr2: [{
        id: 10,
        date: '2020-08-06',
        title: 'EP.02 Dick Sir有話兒：保濕係美顏...',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu10.png',
        url: 'https://www.iqiyi.com/v_1uucraesc5c.html'
      }, {
        id: 11,
        date: '2020-08-06',
        title: 'EP.01 瘦身減脂有方法：減脂、溶脂、脂肪比例',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu11.png',
        url: 'https://www.iqiyi.com/v_bx1ykw714c.html'
      }, {
        id: 12,
        date: '2020-08-03',
        title: "Girls I can't have - Se...",
        image: 'https://www.szinm2020.com/img/CombineCase/casetu12.png',
        url: 'https://www.iqiyi.com/v_1qehsex46gc.html'
      }, {
        id: 13,
        date: '2020-08-03',
        title: "KAREN CHAN陳涵林 - '當我變成我們...'",
        image: 'https://www.szinm2020.com/img/CombineCase/casetu13.png',
        url: 'https://www.iqiyi.com/v_fzzi9qeezk.html'
      }, {
        id: 14,
        date: '2020-08-03',
        title: '當我變成我們-MV拍攝花絮',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu14.png',
        url: 'https://www.iqiyi.com/v_18giuemsink.html'
      }, {
        id: 15,
        date: '2020-07-30',
        title: 'Luffy-swim',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu15.png',
        url: 'https://www.iqiyi.com/v_pudyz1m4q0.html'
      }, {
        id: 16,
        date: '2020-07-30',
        title: '人見人愛哥基犬被棄慘變惡犬？！',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu16.png',
        url: 'https://www.iqiyi.com/v_12olcsagi20.html'
      }, {
        id: 17,
        date: '2020-07-30',
        title: '成為一條成功的緝毒犬需要經歷哪些訓練？',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu17.png',
        url: 'https://www.iqiyi.com/v_1vx8sc6xvns.html'
      }, {
        id: 18,
        date: '2020-07-30',
        title: 'muscle_brother',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu18.png',
        url: 'https://www.iqiyi.com/v_1zxiqj3qdbk.html'
      }],
      arr3: [{
        id: 19,
        date: '2020-07-30',
        title: 'Make Your Style',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu19.png',
        url: 'https://www.iqiyi.com/v_1eldg8upyjc.html'
      }, {
        id: 20,
        date: '2020-04-30',
        title: '【香港企业协会】与亚太经济接轨，面向世界，走向...',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu20.png',
        url: 'https://www.iqiyi.com/v_19ry7x53f0.html'
      }, {
        id: 21,
        date: '2020-08-14',
        title: '网红营销的好处你知道多少？',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu21.png',
        url: 'https://www.iqiyi.com/v_19ry7z84k4.html'
      }, {
        id: 22,
        date: '2020-04-30',
        title: '创科新媒体招募网红啦~！',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu22.png',
        url: 'https://www.iqiyi.com/v_19ry7z4we8.html'
      }, {
        id: 23,
        date: '2020-04-30',
        title: '创科新媒体，开启2020新一代消费模式',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu23.png',
        url: 'https://www.iqiyi.com/v_19ry7z41ug.html'
      }, {
        id: 24,
        date: '2020-09-15',
        title: '香港的人。世界的事  何旭苇Hugo- 机缘巧...',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu24.png',
        url: 'https://www.iqiyi.com/v_1wmer8ffw7s.html'
      }, {
        id: 25,
        date: '2020-09-14',
        title: 'Staycation系列- 走走台湾 Ep.0...',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu25.png',
        url: 'https://www.iqiyi.com/v_2alfgc54pfg.html'
      }, {
        id: 26,
        date: '2020-09-10',
        title: '狗狗81 - Ep01 - 吉娃娃',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu26.png',
        url: 'https://www.iqiyi.com/v_1kcxzxhofos.html'
      }, {
        id: 27,
        date: '2020-09-09',
        title: '容顏救兵  EP.05 用错仪器，随时丰xio...',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu27.png',
        url: 'https://www.iqiyi.com/v_n1rfgzhprk.html'
      }],
      currentarr: [{
        id: 1,
        date: '2020-08-20',
        title: '职场·后现代  EP.01 疫境前行，面部小颜...',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu01.png',
        url: 'https://www.iqiyi.com/v_128mj6bcxhc.html'
      }, {
        id: 2,
        date: '2020-08-20',
        title: '职场·后现代 EP.02 Jenny Chan...',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu02.png',
        url: 'https://www.iqiyi.com/v_1e6uo0kp6rk.html'
      }, {
        id: 3,
        date: '2020-08-14',
        title: '【职场后现代】 Andy   Change y...',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu03.png',
        url: 'https://www.iqiyi.com/v_1qehsex46gc.html'
      }, {
        id: 4,
        date: '2020-08-13',
        title: '盛装出席INM开业、张可頤望与民间高手合作！！',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu04.png',
        url: 'https://www.iqiyi.com/v_n0li6owwqc.html'
      }, {
        id: 5,
        date: '2020-08-13',
        title: '李惠敏首次与INM合作、分享新手妈妈养儿经',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu05.png',
        url: 'https://www.iqiyi.com/v_1n5tp1o8cgc.html'
      }, {
        id: 6,
        date: '2020-08-13',
        title: '蒋怡透露建立烹饪频道、分享星級入厨心得！！',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu06.png',
        url: 'https://www.iqiyi.com/v_be64aya7mo.html'
      }, {
        id: 7,
        date: '2020-08-13',
        title: '叶韦彤双线发展、预告将举办音乐比赛！！',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu07.png',
        url: 'https://www.iqiyi.com/v_1wmer8ffw7s.html'
      }, {
        id: 8,
        date: '2020-08-13',
        title: '容颜救兵，EP.04黑，白咖啡好坏你要知道',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu08.png',
        url: 'https://www.iqiyi.com/v_1tvy8ydmyek.html'
      }, {
        id: 9,
        date: '2020-08-13',
        title: 'EP.03 急救面膜點揀好？DickSir教你...',
        image: 'https://www.szinm2020.com/img/CombineCase/casetu09.png',
        url: 'https://www.iqiyi.com/v_1kcxzxhofos.html'
      }]
    }
  },
  /* components: {
    Froms: Froms
  }, */
  watch: {

  },
  methods: {
    handleinfo () {
      this.show = true
    },
    onSubmit () {
      this.$refs.form.validate((valid) => {
        var that = this
        console.log(this.form)
        if (valid) {
          axios.post('_api.php', this.form).then(
            function (response) {
              if (response.data.code === 200) {
                that.$message({
                  message: '报名成功，请等待我们的工作人员与您联系',
                  type: 'success'
                })
                setTimeout(() => {
                  location.reload()
                }, 2000)
              } else {
                that.$message.error('网络错误！')
              }
            }
          )
        }
      })
      // 校验参数
    },
    handleScroll () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop >= 500) {
        this.show1 = true
      } else {
        this.show1 = false
      }
    },
    handle1 () {
      document.querySelector('video').scrollIntoView({
        behavior: 'smooth'
      })
    },
    handle2 () {
      document.getElementsByClassName('title1')[0].scrollIntoView({
        behavior: 'smooth'
      })
    },
    handle3 () {
      document.getElementsByClassName('swiper-container')[0].scrollIntoView({
        behavior: 'smooth'
      })
    },
    handle4 () {
      document.getElementsByClassName('maodian1')[0].scrollIntoView({
        behavior: 'smooth'
      })
    },
    handle5 () {
      document.getElementsByClassName('maodian5')[0].scrollIntoView({
        behavior: 'smooth'
      })
    },
    handle6 () {
      document.getElementsByClassName('foot')[0].scrollIntoView({
        behavior: 'smooth'
      })
    },
    handleCurrentChange (val) {
      switch (val) {
        case 1:
          this.currentarr = this.arr1
          break
        case 2:
          this.currentarr = this.arr2
          break
        case 3:
          this.currentarr = this.arr3
          break
        default:
          this.currentarr = this.arr1
      }
    },
    handler ({ BMap, map }) {
      console.log(BMap, map)
      this.center.lng = 114.064052
      this.center.lat = 22.610619
      this.zoom = 15
    },
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    }

  },
  mounted () {
    new Swiper('.swiper-container', {
      // 如果需要分页器
      slidesPerView: 7,
      centeredSlides: true,
      centeredSlidesBounds: true,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      speed: 300,
      autoplay: {
        delay: 1000
      }
    })
    window.addEventListener('scroll', this.handleScroll)
  }
}
</script>
<style lang="scss">
  .hander{
    text-align: center;
    line-height: 20px;
    /* background: url('https://www.szinm2020.com/img/aboutCk/rlrl.png') no-repeat center; */
    margin-bottom: 10px;
      .p1{
      font-size: 24px;
      line-height: 30px;
      }
      .p2{
        font-size: 20px;
        line-height: 30px;
      }
    .p3{
      width: 94vw;
      margin: 0 auto;
      font-size: 14px;
    }
  }
  .youshi{
    text-align: center;
    .p1{
      font-size: 24px;
      line-height: 30px;
    }
    .tupian{
      li{
        line-height: 0px;
        position: relative;
      }
    }

  }
  .yewufanwei1{
    width: 96vw;
    margin: 0 auto;
    text-align: center;
    .p1{
      font-size: 24px;
      line-height: 30px;
    }
    .yewufanwei2{
      display: flex;
      overflow: hidden;
    ul{
      li{
        text-align: left;
        font-size: 14px;
        p{
          width: 70%;
          line-height: 20px;
          margin: 0 auto;
        }
        img{
          width: 10%;
          max-width: 50px;
          margin-left: 4%;
          float: left;
        }
      }
    }
    }
  }
  .hongrenzhaomu{
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2);
      /* background: url('https://www.szinm2020.com/img/RedPavilion/RedPavilion-bg.png'); */
      .p1{
        font-size: 24px;
        line-height: 36px;
        color: red;
      }
      .p2{
        font-size: 14px;
        line-height: 24px;
        color: #fff;
      }
        img{
          width:100vw
          }
      .baoming{
        padding: 10px 0;
        .anniu{
          border-radius: 10px;
        }
      }
  }

  .hezuoanli2{
    font-size: 14px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      .page_div{
        margin: 30px auto;
      }
      .case{
        width: 33%;
        text-align: center;
        margin: 2px 0;
        img{
          margin-top: 5px;
        }
        p{
          text-align: left;
          width: 80%;
          margin-left: 10%;
          margin-top: 5px;
        }
      }
    }
  .lianxiwomen2{
    position: relative;
    padding-bottom: 30px;
    .erweima2{
      width: 25%;
    }
    .lianxiwomenwenzi{
      font-size: 14px;
      position: absolute;
      left: 26%;
      top: 310px;
      line-height: 16px;
    }
  }
  .footer2{
    text-align: center;
    margin-top: 5px;
    line-height: 20px;
  }
  /* .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  } */
</style>
