<template>
    <div class="home">
        <div class="handeee" v-show="show3">
      <img src="https://szinm2020.com/img/log.png" alt="">
    </div>
        <van-icon class="icon" size='25px' @click="handlehome" name="wap-home-o" v-show="show3"/>
        <div class="show" v-show="show2" @click="handle10"></div>
        <van-tree-select
        :items="items"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        height="105vh"
        @click-nav="handle0"
        v-show="show2"
        />
        <tabbar>
        <ul class="dh">
        <li @click="handle1">首页</li>
        <li @click="handle1">关于创科</li>
        <li @click="handle1">业务范围</li>
        <li @click="handle1">红人馆</li>
        <li @click="handle1">合作案例</li>
        <li @click="handle6">商务合作</li>
        </ul>
        </tabbar>
        <video class="video maodian" src="https://www.szinm2020.com/img/HomePage/0826-INM-%E5%AA%92%E4%BD%93%E4%BC%98%E5%8A%BF%EF%BC%88%E5%9B%BD%E8%AF%AD%EF%BC%89-30s%20(2).mp4" autoplay loop muted width="100%"></video>
        <div class="nav maodian">
              <div>
                  <div class="title1">
                <p class="gywenzi">资源丰富</p>
                <p class="zimuys">ABUNDANT RESOURCES</p>
              </div>
              </div>
              </div>
              <div class="introducep">
                <p>
                创科新媒体的左膀右臂同为各自业内的领军企业【香港天御国际集团】是香港美容业的行业标杆，活跃在全球各大展会，了解世界的动向，
                采购优质的国际商品并将其引入国内，做国内最优质的全球供应链，凭借优质的服务和专业的团队，斩获无数奖项
                【亚太媒体文化协会】作为一家集创意、策划、设计、制作、投放于一体的综合性市场策划公关公司，专业策划执行方案，一体化综合服务，
                天马行空的创意扎实牢靠的落地能力都是亚太媒体实力的彰显与此同时，创科新媒体同【香港企业协会】【香港医学专业美容协会】【香港宝石协会】
                【香港专业体检协会】有长期密切的合作关系。涉及演艺、供应链、美容、珠宝、健康等多个方面，多维度，全方面的洞悉市场需求
            </p>
              </div>
              <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_1.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_2.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_3.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_4.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_5.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_6.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_7.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_8.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_9.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_10.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_11.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_12.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_13.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_14.jpg" alt=""></div>
            </div>
          </div>
          <van-swipe class="lunbo" :autoplay="1000" :show-indicators='false'>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/aboutCk/logo_1.jpg" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/aboutCk/logo_2.jpg" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/aboutCk/logo_3.jpg" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/aboutCk/logo_4.jpg" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/aboutCk/logo_5.jpg" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/aboutCk/logo_6.jpg" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/aboutCk/logo_7.jpg" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/aboutCk/logo_8.jpg" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/aboutCk/logo_9.jpg" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/aboutCk/logo_10.jpg" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/aboutCk/logo_11.jpg" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/aboutCk/logo_12.jpg" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/aboutCk/logo_13.jpg" alt="">
              </van-swipe-item>
              <van-swipe-item>
                <img src="https://www.szinm2020.com/img/aboutCk/logo_14.jpg" alt="">
              </van-swipe-item>
            </van-swipe>
        <!-- <div class="swiper-container2">
            <div class="swiper-wrapper">
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_1.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_2.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_3.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_4.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_5.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_6.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_7.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_8.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_9.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_10.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_11.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_12.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_13.jpg" alt=""></div>
                <div class="swiper-slide"><img src="https://www.szinm2020.com/img/aboutCk/logo_14.jpg" alt=""></div>
            </div>
          </div> -->
        <!-- <div class="youshi">
            <van-swipe :autoplay="3000" >
              <van-swipe-item v-for="image in oImg" :key="image.id"  >
                <img :src="image.src" width="100%">
              </van-swipe-item>
            </van-swipe>
        </div> -->
        <!-- <div class="title2img">
                <ul>
                  <li v-for="item in oImg" :key="item.id">
                    <img :src="item.src" alt="">
                  </li>
                </ul>
        </div> -->
        <div style="background:#f0f4f7">
        <div class="foot" id="foot">
                    <img class="erweima" src="https://www.szinm2020.com/img/CombineCase/QRcode.png" />
                    <div class="lianxiwomen">
                        <div class="saoma"><img src="https://www.szinm2020.com/img/CombineCase/xxioc.png" /><span>扫码联系客服</span></div>
                        <p><img src="https://www.szinm2020.com/img/CombineCase/zuojiioc.png" /><span>info@inm2020.com</span></p>
                        <p><img src="https://www.szinm2020.com/img/CombineCase/sjioc.png" /><span>135-2875-1015</span></p>
                        <p><img src="https://www.szinm2020.com/img/CombineCase/dizioc.png" /><span>深圳市 龙华区 星河World二期 C座20层</span></p>
                    </div>
                </div>
                <baidu-map class="map"  :center="center" :zoom="zoom" @ready="handler" :scroll-wheel-zoom="true">
                <bm-scale anchor="BMAP_ANCHOR_TOP_LEFT"></bm-scale>
                <!-- <bm-geolocation anchor="BMAP_ANCHOR_TOP_LEFT" :showAddressBar="true" :autoLocation="true"></bm-geolocation> -->
                <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT" ></bm-navigation>
                <bm-city-list anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-city-list>
                <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_SATELLITE_MAP']" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-map-type>
                <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>
                <bm-marker :position="center" :dragging="true" @click="infoWindowOpen">
                <bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen">
                    <p>创科新媒体</p>
                    <p>地址：深圳市 龙华区 星河WORLD二期C座 深圳创科新媒体</p>
                    <p>电话：135-2875-1015</p>
                    </bm-info-window>
                </bm-marker>
                </baidu-map>
            </div>
            <div class="footer">
            <img class="erweima" src="https://www.szinm2020.com/img/CombineCase/huiselogo.png" />
                    <span class="peianh">Copyright © 2020 szinm2020.com. <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2020092676号</a></span>
            </div>
  </div>
</template>
<script>
import Swiper from 'swiper/bundle' // 引入swiper.js
import 'swiper/swiper-bundle.min.css' // 引入swiper.css
import tabbar from '../../components/tabbar'
export default {
  data () {
    return {
      activeId: 1,
      activeIndex: 6,
      items: [{ text: '首页' }, { text: '关于创科' }, { text: '业务范围' }, { text: '红人馆' }, { text: '合作案例' }, { text: '商务合作' }],
      show: false,
      show1: false,
      show2: false,
      show3: true,
      center: { lng: 0, lat: 0 },
      // center: {lng: 114.064052, lat: 22.610619},
      zoom: 15,
      oImg: [
        {
          id: 1,
          src: 'https://www.szinm2020.com/img/aboutCk/logo_1.jpg'
        },
        {
          id: 2,
          src: 'https://www.szinm2020.com/img/aboutCk/logo_2.jpg'
        },
        {
          id: 3,
          src: 'https://www.szinm2020.com/img/aboutCk/logo_3.jpg'
        },
        {
          id: 4,
          src: 'https://www.szinm2020.com/img/aboutCk/logo_4.jpg'
        },
        {
          id: 5,
          src: 'https://www.szinm2020.com/img/aboutCk/logo_5.jpg'
        },
        {
          id: 6,
          src: 'https://www.szinm2020.com/img/aboutCk/logo_6.jpg'
        },
        {
          id: 7,
          src: 'https://www.szinm2020.com/img/aboutCk/logo_7.jpg'
        },
        {
          id: 8,
          src: 'https://www.szinm2020.com/img/aboutCk/logo_8.jpg'
        },
        {
          id: 9,
          src: 'https://www.szinm2020.com/img/aboutCk/logo_9.jpg'
        },
        {
          id: 10,
          src: 'https://www.szinm2020.com/img/aboutCk/logo_10.jpg'
        },
        {
          id: 11,
          src: 'https://www.szinm2020.com/img/aboutCk/logo_11.jpg'
        },
        {
          id: 12,
          src: 'https://www.szinm2020.com/img/aboutCk/logo_12.jpg'
        },
        {
          id: 13,
          src: 'https://www.szinm2020.com/img/aboutCk/logo_13.jpg'
        },
        {
          id: 14,
          src: 'https://www.szinm2020.com/img/aboutCk/logo_14.jpg'
        }
      ]
    }
  },
  components: {
    tabbar: tabbar
  },
  mounted () {
    Swiper('.swiper-container', {
      // 如果需要分页器
      slidesPerView: 3,
      centeredSlides: true,
      centeredSlidesBounds: true,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      speed: 300,
      autoplay: {
        delay: 1000
      }
    })
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var currentVal = scrollTop - this.oldScrollTop
      this.oldScrollTop = scrollTop
      if (currentVal < 0) {
        this.show3 = true
      } else {
        this.show3 = false
      }
      if (scrollTop >= 500) {
        this.show1 = true
      } else {
        this.show1 = false
      }
    },
    handlehome () {
      this.show2 = true
    },
    handle10 () {
      this.show2 = false
    },
    handle0 (index) {
      if (index === 0) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 1) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 2) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 3) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 4) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 5) {
        this.handle6()
        this.show2 = false
        this.activeIndex = 6
      }
    },
    handler ({ BMap, map }) {
      console.log(BMap, map)
      this.center.lng = 114.064052
      this.center.lat = 22.610619
      this.zoom = 15
    },
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    },
    handle1 () {
      this.$router.push('/home')
    },
    handle6 () {
      document.getElementsByClassName('foot')[0].scrollIntoView({
        behavior: 'smooth'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
    .swiper-container{
      margin-top: 20px;
    }
    .lunbo{
      margin-top: 20px;
    }
</style>
