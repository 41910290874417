<template>
    <div class="home">
        <div class="handeee" v-show="show3">
      <img src="https://szinm2020.com/img/log.png" alt="">
    </div>
        <van-icon class="icon" size='25px' @click="handlehome" name="wap-home-o" v-show="show3"/>
        <div class="show" v-show="show2" @click="handle10"></div>
        <van-tree-select
        :items="items"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        height="105vh"
        @click-nav="handle0"
        v-show="show2"
        />
        <tabbar>
        <ul class="dh">
        <li @click="handle1">首页</li>
        <li @click="handle1">关于创科</li>
        <li @click="handle1">业务范围</li>
        <li @click="handle1">红人馆</li>
        <li @click="handle1">合作案例</li>
        <li @click="handle6">商务合作</li>
        </ul>
        </tabbar>
        <video class="video maodian" src="https://www.szinm2020.com/img/HomePage/0826-INM-%E5%AA%92%E4%BD%93%E4%BC%98%E5%8A%BF%EF%BC%88%E5%9B%BD%E8%AF%AD%EF%BC%89-30s%20(2).mp4" autoplay loop muted width="100%"></video>
        <div class="nav maodian">
              <div>
                  <div class="title1">
                <p class="gywenzi">专业团队</p>
                <p class="zimuys">PROFESSIONAL TEAM</p>
              </div>
              </div>
              </div>
              <div class="introducep">
                <p>
                作为香港最早的一批新媒体企业，创科严格秉承着“共创、共享、共赢”这一企业信条，在众多同业中快速脱颖而出。值得一提的是，
                创科不同于其他稂莠不齐的新媒体企业，在新媒体产生发展迅速的同时，没有一味地将传统媒体彻底摒弃，而是尝试把传统媒体中
                的精华部分与新媒体这一互联网的产物相结合，线上与线下同时产出。这种方式一出，便在业内掀起一股热潮，创科做为发起人，
                砥砺前行至今。如今的创科，俨然成为行业的标杆，在实战中反复打磨累，积了无数好评。形成一套以专业体系、专业架构、专业
                人员为一体的专业团队，不负你的期待！
            </p>
              </div>
              <div class="youshi">
            <van-swipe :autoplay="3000" >
              <van-swipe-item v-for="image in oImg" :key="image.id"  >
                <img :src="image.src" width="100%">
              </van-swipe-item>
            </van-swipe>
        </div>
              <div class="title2img">
                <ul>
                  <li v-for="item in oImg" :key="item.id">
                    <img :src="item.src" alt="">
                  </li>
                </ul>
              </div>
        <div style="background:#f0f4f7">
        <div class="foot" id="foot">
                    <img class="erweima" src="https://www.szinm2020.com/img/CombineCase/QRcode.png" />
                    <div class="lianxiwomen">
                        <div class="saoma"><img src="https://www.szinm2020.com/img/CombineCase/xxioc.png" /><span>扫码联系客服</span></div>
                        <p><img src="https://www.szinm2020.com/img/CombineCase/zuojiioc.png" /><span>info@inm2020.com</span></p>
                        <p><img src="https://www.szinm2020.com/img/CombineCase/sjioc.png" /><span>135-2875-1015</span></p>
                        <p><img src="https://www.szinm2020.com/img/CombineCase/dizioc.png" /><span>深圳市 龙华区 星河World二期 C座20层</span></p>
                    </div>
                </div>
                <baidu-map class="map"  :center="center" :zoom="zoom" @ready="handler" :scroll-wheel-zoom="true">
                <bm-scale anchor="BMAP_ANCHOR_TOP_LEFT"></bm-scale>
                <!-- <bm-geolocation anchor="BMAP_ANCHOR_TOP_LEFT" :showAddressBar="true" :autoLocation="true"></bm-geolocation> -->
                <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT" ></bm-navigation>
                <bm-city-list anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-city-list>
                <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_SATELLITE_MAP']" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-map-type>
                <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>
                <bm-marker :position="center" :dragging="true" @click="infoWindowOpen">
                <bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen">
                    <p>创科新媒体</p>
                    <p>地址：深圳市 龙华区 星河WORLD二期C座 深圳创科新媒体</p>
                    <p>电话：135-2875-1015</p>
                    </bm-info-window>
                </bm-marker>
                </baidu-map>
            </div>
            <div class="footer">
            <img class="erweima" src="https://www.szinm2020.com/img/CombineCase/huiselogo.png" />
                    <span class="peianh">Copyright © 2020 szinm2020.com. <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2020092676号</a></span>
            </div>
  </div>
</template>
<script>
import tabbar from '../../components/tabbar'
export default {
  data () {
    return {
      activeId: 1,
      activeIndex: 6,
      items: [{ text: '首页' }, { text: '关于创科' }, { text: '业务范围' }, { text: '红人馆' }, { text: '合作案例' }, { text: '商务合作' }],
      show: false,
      show1: false,
      show2: false,
      show3: true,
      center: { lng: 0, lat: 0 },
      // center: {lng: 114.064052, lat: 22.610619},
      zoom: 15,
      oImg: [
        {
          id: 1,
          src: 'https://www.szinm2020.com/img/aboutCk/profession4.jpg'
        },
        {
          id: 2,
          src: 'https://www.szinm2020.com/img/aboutCk/profession2.jpg'
        },
        {
          id: 3,
          src: 'https://www.szinm2020.com/img/aboutCk/profession3.jpg'
        },
        {
          id: 4,
          src: 'https://www.szinm2020.com/img/aboutCk/profession1.jpg'
        }
      ]
    }
  },
  components: {
    tabbar: tabbar
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var currentVal = scrollTop - this.oldScrollTop
      this.oldScrollTop = scrollTop
      if (currentVal < 0) {
        this.show3 = true
      } else {
        this.show3 = false
      }
      if (scrollTop >= 500) {
        this.show1 = true
      } else {
        this.show1 = false
      }
    },
    handlehome () {
      this.show2 = true
    },
    handle10 () {
      this.show2 = false
    },
    handle0 (index) {
      if (index === 0) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 1) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 2) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 3) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 4) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 5) {
        this.handle6()
        this.show2 = false
        this.activeIndex = 6
      }
    },
    handler ({ BMap, map }) {
      console.log(BMap, map)
      this.center.lng = 114.064052
      this.center.lat = 22.610619
      this.zoom = 15
    },
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    },
    handle1 () {
      this.$router.push('/home')
    },
    handle6 () {
      document.getElementsByClassName('foot')[0].scrollIntoView({
        behavior: 'smooth'
      })
    }
  }
}
</script>
<style lang="scss">

</style>
