import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Strength from '../views/advantage/strength.vue'
import Profession from '../views/advantage/profession.vue'
import Resource from '../views/advantage/resource.vue'
import Project from '../views/advantage/project.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/strength',
    name: 'Strength',
    component: Strength
  },
  {
    path: '/profession',
    name: 'Profession',
    component: Profession
  },
  {
    path: '/resource',
    name: 'Resource',
    component: Resource
  },
  {
    path: '/project',
    name: 'Project',
    component: Project
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '*',
    redirect: '/home'
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
