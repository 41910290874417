<template>
    <div class="home">
        <div class="handeee" v-show="show3">
      <img src="https://szinm2020.com/img/log.png" alt="">
    </div>
        <van-icon class="icon" size='25px' @click="handlehome" name="wap-home-o" v-show="show3"/>
        <div class="show" v-show="show2" @click="handle10"></div>
        <van-tree-select
        :items="items"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        height="105vh"
        @click-nav="handle0"
        v-show="show2"
        />
        <tabbar>
        <ul class="dh">
        <li @click="handle1">首页</li>
        <li @click="handle1">关于创科</li>
        <li @click="handle1">业务范围</li>
        <li @click="handle1">红人馆</li>
        <li @click="handle1">合作案例</li>
        <li @click="handle6">商务合作</li>
        </ul>
        </tabbar>
        <video class="video maodian" src="https://www.szinm2020.com/img/HomePage/0826-INM-%E5%AA%92%E4%BD%93%E4%BC%98%E5%8A%BF%EF%BC%88%E5%9B%BD%E8%AF%AD%EF%BC%89-30s%20(2).mp4" autoplay loop muted width="100%"></video>
        <div class="nav maodian">
              <div>
                  <div class="title1">
                <p class="gywenzi">专属方案</p>
                <p class="zimuys">EXCLUSIVE SOLUTION</p>
              </div>
              </div>
              </div>
              <div class="introducep">
                <p>
                数十家的深度合作社交媒体平台，雄厚的媒体资源，覆盖全主流领域的社交电商平台，定位不同用户群体，满足企业品牌曝光、产品销售等一系列精准营销需求创科承诺，
                针对每一位客人独家定制专属方案，结合特性、基调、风格、市场、卖点等在众多的自媒体平台中选择最适合，繁杂的算法中选择最优解。快速变现，急速涨粉，树立企业
                及个人IP形象，让您用最简单直接的方式看到收益将继续深化多方合作，广纳优质品牌商、优质才艺技能培训机构、网红KOL资源，以新云商媒体营销与全球市场无缝接轨，
                精准再精准，有效更高效。
            </p>
              </div>
              <div class="youshi">
            <van-swipe :autoplay="3000" >
              <van-swipe-item v-for="image in oImg" :key="image.id"  >
                <img :src="image.src" width="100%">
              </van-swipe-item>
            </van-swipe>
        </div>
              <div class="title2img">
                <ul>
                  <li v-for="item in oImg" :key="item.id">
                    <img :src="item.src" alt="">
                  </li>
                </ul>
              </div>
        <div style="background:#f0f4f7">
        <div class="foot" id="foot">
                    <img class="erweima" src="https://www.szinm2020.com/img/CombineCase/QRcode.png" />
                    <div class="lianxiwomen">
                        <div class="saoma"><img src="https://www.szinm2020.com/img/CombineCase/xxioc.png" /><span>扫码联系客服</span></div>
                        <p><img src="https://www.szinm2020.com/img/CombineCase/zuojiioc.png" /><span>info@inm2020.com</span></p>
                        <p><img src="https://www.szinm2020.com/img/CombineCase/sjioc.png" /><span>135-2875-1015</span></p>
                        <p><img src="https://www.szinm2020.com/img/CombineCase/dizioc.png" /><span>深圳市 龙华区 星河World二期 C座20层</span></p>
                    </div>
                </div>
                <baidu-map class="map"  :center="center" :zoom="zoom" @ready="handler" :scroll-wheel-zoom="true">
                <bm-scale anchor="BMAP_ANCHOR_TOP_LEFT"></bm-scale>
                <!-- <bm-geolocation anchor="BMAP_ANCHOR_TOP_LEFT" :showAddressBar="true" :autoLocation="true"></bm-geolocation> -->
                <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT" ></bm-navigation>
                <bm-city-list anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-city-list>
                <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_SATELLITE_MAP']" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-map-type>
                <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>
                <bm-marker :position="center" :dragging="true" @click="infoWindowOpen">
                <bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen">
                    <p>创科新媒体</p>
                    <p>地址：深圳市 龙华区 星河WORLD二期C座 深圳创科新媒体</p>
                    <p>电话：135-2875-1015</p>
                    </bm-info-window>
                </bm-marker>
                </baidu-map>
            </div>
            <div class="footer">
            <img class="erweima" src="https://www.szinm2020.com/img/CombineCase/huiselogo.png" />
                    <span class="peianh">Copyright © 2020 szinm2020.com. <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2020092676号</a></span>
            </div>
  </div>
</template>
<script>
import tabbar from '../../components/tabbar'
export default {
  data () {
    return {
      activeId: 1,
      activeIndex: 6,
      items: [{ text: '首页' }, { text: '关于创科' }, { text: '业务范围' }, { text: '红人馆' }, { text: '合作案例' }, { text: '商务合作' }],
      show: false,
      show1: false,
      show2: false,
      show3: true,
      center: { lng: 0, lat: 0 },
      // center: {lng: 114.064052, lat: 22.610619},
      zoom: 15,
      oImg: [
        {
          id: 1,
          src: 'https://www.szinm2020.com/img/%E5%9B%BE%E7%89%872.png'
        },
        {
          id: 2,
          src: 'https://www.szinm2020.com/img/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20210515115023.png'
        }
      ]
    }
  },
  components: {
    tabbar: tabbar
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var currentVal = scrollTop - this.oldScrollTop
      this.oldScrollTop = scrollTop
      if (currentVal < 0) {
        this.show3 = true
      } else {
        this.show3 = false
      }
      if (scrollTop >= 500) {
        this.show1 = true
      } else {
        this.show1 = false
      }
    },
    handlehome () {
      this.show2 = true
    },
    handle10 () {
      this.show2 = false
    },
    handle0 (index) {
      if (index === 0) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 1) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 2) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 3) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 4) {
        this.handle1()
        this.show2 = false
        this.activeIndex = 6
      } else if (index === 5) {
        this.handle6()
        this.show2 = false
        this.activeIndex = 6
      }
    },
    handler ({ BMap, map }) {
      console.log(BMap, map)
      this.center.lng = 114.064052
      this.center.lat = 22.610619
      this.zoom = 15
    },
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    },
    handle1 () {
      this.$router.push('/home')
    },
    handle6 () {
      document.getElementsByClassName('foot')[0].scrollIntoView({
        behavior: 'smooth'
      })
    }
  }
}
</script>
<style lang="scss">

</style>
