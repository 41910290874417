<template>
  <div id="app" >
    <!-- <div>11111</div>
    <tabbar></tabbar>
    <froms></froms> -->
    <!-- <router-link ></router-link> -->
    <router-view></router-view>
  </div>
</template>
<script>
/* import Tabbar from './components/tabbar'
import Froms from './components/Froms.vue' */
export default {
  data () {
    return {

    }
  },
  methods: {
    /* aaa(){
          console.log(111);
        },
        _isMobile() {
          let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
          return flag;
        } */
  },
  mounted () {
    /* if (this._isMobile()) {
      console.log('umi');
      this.$router.replace('/about');
    } else {
      console.log('pc');
      this.$router.replace('/home');
    } */
  }
  /* components: {
    Tabbar: Tabbar,
    Froms: Froms
  } */

}
</script>

<style>
</style>
