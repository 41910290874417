<template>
  <div>
    <div class="heard">
    <div class="leftlogo">
      <img src="https://www.szinm2020.com/img/HomePage/logo.png" alt="">
    </div>
    <div class="right">
      <slot></slot>
    </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">

</style>
